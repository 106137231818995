import React from "react"
import ImageRenderer from "../ImageRenderer/ImageRenderer"

// import Logo from "-!svg-react-loader?props[]=className:!../../images/svg/test.inline.svg";
import "./HeroSlide.scss"

export default class HeroSlide extends React.Component {
  onScrollDown = () => {
    //scroll by the window height minus the menu and or the separator
    if (window.pageYOffset === 0) {
      window.scrollBy({
        top: window.innerHeight - 10,
        left: 0,
        behavior: "smooth",
      })
    } else {
      window.scrollBy({
        top: (window.innerHeight - window.pageYOffset) - 10,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="hero-slide d-flex bg-primary">
          <div className="container d-flex flex-column">
            <div className="row flex-grow-1 align-items-center position-relative">
              <div className="col-12 col-xl-7 d-flex flex-column justify-content-center z-index-3 mt-auto mt-md-0">
                <h1 className="fs-10 primary-font font-bold color-white">
                  More Sales. Less Marketing Costs.
                </h1>

                <p className="fs-6 lh-48p text-light">
                  Adhub is a MarTech platform that empowers marketing and business automation.                  
                </p>
              </div>
              <div className="hero-slide__image-container d-flex justify-content-center z-index-2 mb-auto mb-md-0">
                <div className="hero-slide__image">
                  <ImageRenderer alt="Logo" loading="eager" filename="home-bg-header.png" />
                </div>
              </div>

              <div
                className="hero-slide__scroll-down bounce-1 d-flex hover-pointer"
                onClick={this.onScrollDown}
              >
                <ImageRenderer
                  alt="Arrow-down"
                  width="34px"
                  filename="arrow-down.png"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}
