import React from "react"
import ImageRenderer from "../ImageRenderer/ImageRenderer"
import { Link } from "gatsby"

import "./Footer.scss"

export default class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const date = new Date()

    return (
      <footer className="footer bg-primary color-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 d-none d-lg-block">
              <div className="footer__logo">
                <ImageRenderer
                  alt="Logo"
                  width="207px"
                  filename="header-logo.png"
                />
              </div>
            </div>
            <div className="col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start">
              <div>
                <h5 className="fs-1 mb-3 text-upper text-light text-center text-lg-left">
                  ADHUB
                </h5>
                <ul className="footer__related-menu no-style text-center text-lg-left">
                  <li className="mb-2">
                    <Link className="fs-3" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="fs-3" to="/products/estate">
                      Adhub Estate
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="fs-3" to="/about">
                      About
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="fs-3" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*
						<div className="col-12 col-lg-2">
							<h5 className="fs-1 mb-3 text-upper text-light">Products</h5>
							<ul className="footer__related-menu no-style">
								<li className="mb-2">
									<Link className="fs-3" to="/">
										Adhub Estate
									</Link>
								</li>
								<li className="mb-2">
									<Link className="fs-3" to="/about">
										Adhub Auto
									</Link>
								</li>
								<li className="mb-2">
									<Link className="fs-3" to="/contact">
										Adhub Auction
									</Link>
								</li>
							</ul>
						</div>
						*/}
            <div className="col-12 col-lg-2 d-flex flex-column align-items-center align-items-lg-start">
              <div>
                <h5 className="fs-1 mb-3 text-upper text-light text-center text-lg-left">
                  SOCIAL MEDIA
                </h5>
                <ul className="footer__related-menu no-style text-center text-lg-left">
                  <li className="mb-3">
                    <a
                      href="https://www.facebook.com/adhubdk"
                      className="d-flex fs-4 align-items-center"
                    >
                      <span className="mr-2">
                        <ImageRenderer
                          alt="facebook-icon"
                          width="24px"
                          filename="icon-images/facebook-icon.png"
                        />
                      </span>
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li className="mb-3">
                    <a
                      href="https://www.linkedin.com/company/adhubdk"
                      className="d-flex fs-4 align-items-center justify-content-center justify-content-lg-start"
                    >
                      <span className="mr-2">
                        <ImageRenderer
                          alt="linkedin-icon"
                          width="24px"
                          filename="icon-images/linkedin-icon.png"
                        />
                      </span>
                      <span>LinkedIn</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="spacer" />
          <div className="row footer__copy">
            <div className="footer__copy__rights col-12 col-md-6 col-lg-6 fs-2 text-lighter">
              <span>Copyright &copy; {date.getFullYear()} AdHub ApS </span>
              &bull;
              <span> All Rights Reserved</span>
            </div>
            <div className="footer__copy__terms col-12 col-md-6 col-lg-6 fs-2 text-lighter">
              <ul className="related-menu no-style d-flex justify-content-center justify-content-md-end  mb-0">
                <li className="mr-2">
                  <Link className="fs-2" to="/terms-and-service">
                    Terms and Services
                  </Link>
                </li>
                <li className="mr-2">&bull;</li>
                <li className="mr-2">
                  <Link className="fs-2" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
