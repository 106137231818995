import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import "./SubscribeFormLP.scss"

class SubscribeFormLP extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: undefined,
      phone: undefined,
      email: undefined,
      status: undefined,
    }
  }

  handleChange = e => {
    this.statusMessage.style.display = "none"
    this.setState({ [e.target.name]: e.target.value, status: undefined })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { name, phone, email } = this.state
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gm

    if (!emailRegex.test(email)) {
      this.setState({ status: "Email looks invalid!" })
      this.statusMessage.style.display = "block"
      return
    }

    addToMailchimp(email, {
      NAME: name,
      PHONE: phone,
    }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        this.statusMessage.style.display = "block"

        if (data.msg.includes("already subscribed")) {
          this.setState({
            status: {
              ...data,
              msg: data.msg.slice(0, data.msg.indexOf(" to list")).concat("."),
            },
          })
        } else {
          this.setState({
            status: data,
          })
        }
      })
      .catch(e => {
        if (e) throw e
      })
  }
  render() {
    const { email, name, phone } = this.state
    return (
      <React.Fragment>
        <div className="d-flex flex-column align-items-center w-100">
          <form
            className="subscribe-form"
            onSubmit={this.handleSubmit}
            ref={el => (this.contactForm = el)}
          >
            <div className="subscribe-form__field d-flex">
              <label className="d-flex w-100" htmlFor="name">
                <span className="subscribe-form__field__label">name</span>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Dit navn"
                  onChange={this.handleChange}
                />
              </label>
            </div>


            <div className="subscribe-form__field d-flex mt-1">
              <label className="d-flex w-100" htmlFor="email">
                <span className="subscribe-form__field__label">email</span>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Din mail"
                  onChange={this.handleChange}
                />
              </label>
            </div>

            <div className="subscribe-form__field d-flex mt-1">
            <label className="d-flex w-100" htmlFor="phone">
              <span className="subscribe-form__field__label">phone</span>
              <input
                id="phone"
                type="tel"
                name="phone"
                placeholder="Din nummer"
                onChange={this.handleChange}
              />
            </label>
            <div className="d-flex align-items-center">
             <input
                disabled={!name || !email || !phone}
                className="subscribe-form__send btn btn-light border-0 ml-2"
                type="submit"
                value="OK"
              /> 
            </div>
          </div>

            <div className="position-relative">
              <div
                className="mt-3 subscribe-form__validation"
                style={{
                  transition: "all .3 ease",
                  display: "block",
                  left: "0",
                  right: "100px",
                  textAlign: "center",
                }}
                ref={el => (this.statusMessage = el)}
              >
                {
                  <h4
                    className="fs-3 mb-0"
                    style={{
                      color:
                        this.state.status &&
                        this.state.status.result === "success"
                          ? "white"
                          : "white",
                    }}
                  >
                    {this.state.status && this.state.status.msg
                      ? this.state.status.msg
                      : null}
                  </h4>
                }
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default SubscribeFormLP
