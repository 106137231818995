import styled, { css } from 'styled-components'

export const SectionSeparatorContainer = styled.div`
  height: 100px;
  background: #f6f9fc;
  position: relative;

  ${(props) => props.bgColor && css`background: ${props.bgColor || `#f6f9fc`};`};

  ${(props) =>
    props.clipRight && css`clip-path: polygon(0 100%, 0 0, 100% 0);margin-top:-1px;`};

  ${(props) =>
    props.clipLeft && css`clip-path: polygon(0 100%, 100% 0, 100% 100%);margin-bottom:-1px;`};
`

export const SectionSeparatorAccent = styled.div`
  display: none;

  ${(props) =>
    (props.accentGreen || props.accentOrange) &&
    css`
      display: none;
      width: 585px;
      height: 36px;
      position: absolute;

      @media (min-width: 1200px) {
        display: block;
      }
    `};

  ${(props) =>
    props.accentGreen &&
    css`
      bottom: -15px;
      background: linear-gradient(354.87deg, #55c3ba 0.09%, #88cca3 99.39%);
      transform: matrix(1, -0.07, 0, 1, 0, 0);
    `};

  ${(props) =>
    props.accentOrange &&
    css`
      width: 413px;
      right: 0;
      top: 7px;
      background: #f9b39a;
      transform: matrix(1, -0.07, 0, 1, 0, 0);
    `};
`
