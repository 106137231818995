import React from "react"

import Layout from "../layout/layout"
import IntroHero from "../components/IntroHero/IntroHero"
import SEO from "../components/seo"

const slide = {
  title: "Terms and Services",
  subtitle: "Updated: 15 June 2020",
}

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Terms & Conditions" description={`AdHub Terms of Service`} />
    <IntroHero slide={slide} />

    <div className="container my-5 py-5">
      <div className="row">
        <div className="col-12">
          <h1 className="fs-8 primary-font mb-5">AdHub ApS Terms of Service</h1>
          <h2 className="fs-6 mb-3 font-semi-bold">1. Terms</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            By accessing the website at https://adhub.dk, you are agreeing to be
            bound by these terms of service, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable
            copyright and trademark law.
          </p>

          <h2 className="fs-6 mb-4 font-semi-bold">2. Use Licence</h2>
          <ol type="a" className="mb-5">
            <li className="fs-4 lh-40p text-light mb-3">
              Permission is granted to temporarily download one copy of the
              materials (information or software) on AdHub ApS' website for
              personal, non-commercial transitory viewing only. This is the
              grant of a licence, not a transfer of title, and under this
              licence you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on AdHub ApS' website;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </li>
              </ol>
            </li>
            <li className="fs-4 lh-40p text-light">
              This licence shall automatically terminate if you violate any of
              these restrictions and may be terminated by AdHub ApS at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this licence, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ol>

          <h2 className="fs-6 mb-4 font-semi-bold">3. Disclaimer</h2>

          <ol type="a" className="mb-5">
            <li className="fs-4 lh-40p text-light mb-3">
              The materials on AdHub ApS' website are provided on an 'as is'
              basis. AdHub ApS makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </li>
            <li className="fs-4 lh-40p text-light">
              Further, AdHub ApS does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site.
            </li>
          </ol>

          <h2 className="fs-6 mb-4 font-semi-bold">4. Limitations</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            In no event shall AdHub ApS or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on AdHub ApS' website, even if AdHub
            ApS or a AdHub ApS authorised representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>

          <h2 className="fs-6 mb-4 font-semi-bold">5. Accuracy of materials</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            The materials appearing on AdHub ApS' website could include
            technical, typographical, or photographic errors. AdHub ApS does not
            warrant that any of the materials on its website are accurate,
            complete or current. AdHub ApS may make changes to the materials
            contained on its website at any time without notice. However AdHub
            ApS does not make any commitment to update the materials.
          </p>

          <h2 className="fs-6 mb-4 font-semi-bold">6. Links</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            AdHub ApS has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by AdHub ApS of the
            site. Use of any such linked website is at the user's own risk.
          </p>

          <h2 className="fs-6 mb-4 font-semi-bold">7. Modifications</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            AdHub ApS may revise these terms of service for its website at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these terms of service.
          </p>

          <h2 className="fs-6 mb-4 font-semi-bold">8. Governing Law</h2>

          <p className="fs-4 lh-40p text-light mb-5">
            These terms and conditions are governed by and construed in
            accordance with the laws of Denmark and you irrevocably submit to
            the exclusive jurisdiction of the courts in that State or location.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
