import React, { Component } from "react"
import "./adblock-detect.styles.scss"

class AdBlockDetect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBlocked: false,
    }
  }

  async componentDidMount() {
    const usingBlocker = await this.checkAdBlocker()
    this.setState({ isBlocked: usingBlocker })
  }

  checkAdBlocker = async () => {
    let isBlocked

    async function tryRequest() {
      try {
        return fetch(
          new Request(
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
            {
              method: "HEAD",
              mode: "no-cors",
            }
          )
        )
          .then(function(response) {
            document.querySelector("body").style.overflowY = "scroll"
            isBlocked = false
            return isBlocked
          })
          .catch(function(e) {
            isBlocked = true
            document.querySelector("body").style.overflowY = "hidden"
            return isBlocked
          })
      } catch (error) {
        document.querySelector("body").style.overflowY = "hidden"
        isBlocked = true
        return isBlocked
      }
    }

    return isBlocked !== undefined ? isBlocked : await tryRequest()
  }

  reload = () => {
    return window.location.reload()
  }

  render() {
    const { isBlocked } = this.state
    return (
      <React.Fragment>
        {isBlocked && (
          <div className="adblock-warning">
            <div className="adblock-warning__content p-4 d-flex flex-column justify-content-center">
              <h2 className="fs-6 mb-5 text-center mt-auto">
                AdBlock Detected
              </h2>
              <p className="fs-4 text-center">
                We would like to assure you that there aren't any ads running on
                our website. Please pause your Adblock extension or whitelist
                our domain name and press OK to reload.
              </p>
              <div className="d-flex justify-content-center mt-auto mb-auto">
                <a
                  href="#0"
                  onClick={this.reload}
                  className="btn btn-light fs-3 color-secondary color-white"
                >
                  OK
                </a>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default AdBlockDetect
