import React from 'react'
import LayoutLP from '../../layout/layoutLP'
import IntroHeroLP from '../../components/IntroHeroLP/IntroHeroLP'
import TextImageLP from '../../components/TextImageLP/TextImageLP'
import ProductFeatures from '../../components/ProductFeatures/ProductFeatures'
import SectionSeparator from '../../components/section-separator/section-separator.component'
import FadeIn from '../../components/FadeIn/FadeIn'
import SEO from '../../components/seo'
import HeroSlideLP from "../../components/HeroSlideLP/HeroSlideLP"


const slide = {
  logo: 'static/product-estate-logo.png',
  title: 'Adhub Estate',
  subtitle:
    'Dit digitale onlinemarketingsystem​',
  backgroundImage: 'static/product-estate-new.png',
  link: 'mailto:mas@adhub.dk?subject=Book%20Adhub%20Estate%20Demo'
}


const section = {
  heading: '',
  keyword: 'Remarketing',
  keywordType: 'blue',
  content:
    'Med Adhub remarketingkampagner er der altid “styr” på potentielle købere og lejere. Når man aktivere remarketingfunktionen på sager, følger de digitaleannoncer automatisk med når interesserede købere og lejere besøger andre websider og socialemedier.​',
  content2:
    'Annoncerne vises dér hvor de potentielle kunder er på det rigtige tidspunkt, f.eks. på tv2, pol.dk, berlingske.dk, bt.dk, dba.dk m.fl. samt på de sociale medier.',
  imageUrl: 'static/product-estate-remarketing.png'
}
const section2 = {
  heading: 'på 3 “Klik”​',
  keyword: 'Onlineannoncering', 
  keywordType: 'salmon',
  content:
    'Adhub er intelligent, fuldautomatisk og integreret med C&B Boligsystem. Derfor kan man med få “klik” oprette online sagsannoncering på Facebook, Instagram og digitalemediebanner.​',
  imageUrl: 'static/adhub-case-campaign-new.png'
}
const section3 = {
  heading: '& Branding',
  keyword: 'Profil',
  keywordType: 'green',
  content:
    'Adhub opbygger automatisk segmenter som kan anvendes målrettet for at få nye emner til salg.​',
  content2:
    'Udfra de brugere som har udvist interesse for sager digitalt, sammen med den unikke algoritme genereres automatisk målgrupper af potentielle købere og lejere. Med udgangspunkt i disse målgrupper, finder Adhub lignede brugere som har samme interesser og adfærd.​',
  content3:
    'Med segmenterne kan man målrette sin annonceringen til.​ F.eks personer som er interesseret i at få en salgsvurdering',
  imageUrl: 'static/product-estate-profil.png'
}

const section4 = {
  heading: '24/7',
  keyword: 'Rapporter',
  keywordType: 'green',
  content:
    'Adhub genere automatisk statusrapport for sagsannonceringen således at performance kan følges. Via et link kan både sælger og mægler følge med I kampagneforløbet. Rapporten kan også generes som PDF der kan sendes til sælger.​​',
  imageUrl: 'static/feature-campaign-report.png'
}

/* const section5 = {
  heading: 'samlet ét sted giver dig et forretningsoverblik.',
  keyword: 'Alt data',
  keywordType: 'green',
  content:
    'Adhub genere et samlet forretningsoverblik over din onlinemarketingsindsats. På tværs af alle udførte sagskampagner genereres statistik, sådan at den samlede digitale indsats kan måles og vurderes periode for periode.',
  imageUrl: 'static/feature-statistics.png'
}

const section6 = {
  heading: 'over dit online engagement for din forretning.',
  keyword: 'Et samlet overblik',
  keywordType: 'green',
  content:
    'Adhub indholder et samlet forbrugsoverblik over alle udført sagskampagner for en periode. Du kan således nemt skabe dig et overblik over hvad din indtjening har været på Adhub måned for måned.',
  imageUrl: 'static/feature-expenses.png'
} */

const features = [
  {
    icon: 'icon-images/remarketing-campaigns-accent.png',
    headline: 'Onlineannoncering',
    text:
      'Adhub er fuldautomatiseret onlineannoncering på tværs af Google, Facebook, Instagram og mediebanner'
  },
  {
    icon: 'icon-images/facebook-instagram-icons.png',
    headline: 'Facebook & Instagram',
    text:
      'Adhub udnytter de bedste egenskaber ved socialmedier. Data opsamles, segmenteres og målrettes på Facebool og Instagram. Der anvendes multible kombinationer af sagsannoncen som testes for bedst performance'
  },
  {
    icon: 'icon-images/media-icon-accent.png',
    headline: 'Mediebanner',
    text:
      'Mediebanner annonceres på alle kendte mediesites. Lige præcis på det sekundt hvor din bruger befinder sig eks. på Pol.dk, B.dk, Jp.dk, TV2.dk etc., Samtidig tages der højde for den valgte geografi du er interesseret i på dine brugere.'
  },
  {
    icon: 'icon-images/campaign-target-accent.png',
    headline: 'TrueTarget',
    text:
      'Adhub TrueTarget sikre at din annonce er lige der hvor dine købere befinder sig. Dine kampagner optimeres udfra datasegmentering, maskinlæring og AI'
  },
  {
    icon: 'icon-images/interest-groups-accent.png',
    headline: 'Segmenter',
    text:
      'Adhub TrueTarget opbygger og optimerer løbende relevante målgrupper udfra realiserede  kampagnedata. Truetarget integreres med dit website og din forretningsplatform. Segmenterne opbygges unikt udfra dine data'
  },
  {
    icon: 'icon-images/bermuda-icon.png',
    headline: 'Integration',
    text:
      'Adhub er Integreret med dit sagsstyringssystem, din fotoleverandør og dit website. Det muliggøre en fuldautomatisk kampagnegenering - 3 klik og du har aktiveret en online kampagne'
  },
  {
    icon: 'icon-images/campaign-dashboard-accent.png',
    headline: 'Kampagneoverblik',
    text:
      'I Adhub kan du få et samlet overblik over din igangværende og gennemførte onlinemarketing. Du har tilgang til statistik på den enkelte kampagne eller som et samlet overblik over alle kampagner på tværs af en tidsperiode'
  },
  {
    icon: 'icon-images/remarketing-campaigns-accent.png',
    headline: 'Remarketing',
    text:
      'Du vil altid betjene en kunde som kommer ind i din forretning og er interesseret i en bolig! - Helt det samme sikre vi digitalt! - Med "Et klik" er din sag digitalt håndteret'
  },
  {
    icon: 'icon-images/insights-accent.png',
    headline: 'Rapportering & statistik',
    text: 'Adhub generere automatisk en sagsrapportering til dig og til din slutkunde, således at performance altid kan følges mens kampagnen eksekveres'
  },
  {
    icon: 'icon-images/geotargeting-accent.png',
    headline: 'Geotargeting',
    text:
      'Vælg geografiske område som du ønsker annonceringen skal eksekveres i eller lad Adhub optimere dette fuld automatisk'
  },
  {
    icon: 'icon-images/automatic-ab-testing-accent.png',
    headline: 'Automatisk A/B-test',
    text:
      'Adhub testet automatisk en antal kampagnevarianter og prioritere den bedste. Adhub generere automatisk flere varianter af din onlineannonce for at teste forskellige budskaber af for maksimal performance'
  },
  {
    icon: 'icon-images/realtime-preview-accent.png',
    headline: 'Kampagnepreview',
    text:
      'Du kan altid nøjagtigt se hvordan din annoncer vil se ud på SoMe-kanaler, Google Ads og Mediebanner'
  },
  {
    icon: 'icon-images/branding-campaigns-accent.png',
    headline: 'Brandingkampagner',
    text:
      'Du kan selv opsætte og redigere den brandingkampagne du ønsker skal anvendes når en sag er solgt. Du kan oprette så mange brandingkampagner du ønsker med forskellige budskaber'
  },
  {
    icon: 'icon-images/campaign-overview-accent.png',
    headline: 'Kampagneoversigt',
    text: 'Adhub generere et samlet kampagneoverblik over alle kampagner kørt i en given periode. Du kan derfor altid se både reslutatet af din samlede digitale indsats såvel som et tilhørende økonomisk overblik over dine udgifter'
  }
]

const EstateDK = () => (
  <LayoutLP>
    <SEO title="Adhub Estate" description={`Onlineannoncering til Ejendomsmæglere`} />

    <IntroHeroLP slide={slide} />

    <HeroSlideLP />

    <FadeIn element={'.fadeIn-1'}>
      <div className="fadeIn-1">
        <TextImageLP section={section2} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-2'}>
      <div className="fadeIn-2">
        <TextImageLP section={section} leftAlign={true} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-3'}>
      <div className="fadeIn-3">
        <TextImageLP section={section3} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-4'}>
      <div className="fadeIn-4">
        <TextImageLP section={section4} leftAlign={true} />
      </div>
    </FadeIn>

{/*     <FadeIn element={'.fadeIn-5'}>
      <div className="fadeIn-5">
        <TextImageLP section={section5} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-6'}>
      <div className="fadeIn-6">
        <TextImageLP section={section6} leftAlign={true} />
      </div>
    </FadeIn> */}

    <SectionSeparator clipLeft />
    <ProductFeatures headline="Produktegenskaber" features={features} />
    <SectionSeparator clipRight />

  </LayoutLP>
)

export default EstateDK
