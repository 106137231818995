import React from "react"

import Layout from "../layout/layout"
import HeroSlide from "../components/HeroSlide/HeroSlide"
import CaseInfoBox from "../components/case-info-box/case-info-box.component"
import InfoBoxes from "../components/InfoBoxes/InfoBoxes"
import ProductShowcase from "../components/product-showcase/product-showcase.component"
import OurCaseList from "../components/OurCaseList/OurCaseList"
import FadeIn from "../components/FadeIn/FadeIn"
import SectionSeparator from "../components/section-separator/section-separator.component"
import SEO from "../components/seo"

const CaseInfoBoxData = {
  title: "Adhub, award winning software",
  text: `Gold award in data and marketing automation`,
  bullets: [
    `Turnover increased by as much as 63% to a high double-digit million`,
    `Extra sales increased by 387%.`,
    `Data driven end to end automation`,
  ],
  link:
    "https://translate.google.com/translate?sl=auto&tl=en&u=https%3A%2F%2Fdanishdigitalaward.dk%2Fprojekt%2Fedc-effekt-stoerst-synlighed-mest-effekt%2F",
}

const MeetAdhubEstate = {
  logo: "static/product-estate-logo.png",
  title: "Meet Adhub Estate",
  text: "AI powered marketing platform for real estate agents",
  link: "products/estate",
  linkLabel: "Discover Adhub Estate",
  image: "static/dashboard-estate-case.png",
  btnVariant: "btn-primary",
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description={`Adhub is a MarTech platform that empowers marketing and business automation.`}
    />

    <HeroSlide />

    <FadeIn element={".fadeIn-cib"}>
      <div className="fadeIn-cib">
        <CaseInfoBox section={CaseInfoBoxData} />
        <SectionSeparator clipRight />
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-ib"}>
      <div className="fadeIn-ib">
        <InfoBoxes />
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-opl"}>
      <div className="fadeIn-opl">
        <SectionSeparator clipLeft accentOrange />
        <ProductShowcase section={MeetAdhubEstate} />
        <SectionSeparator clipRight accentGreen />
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-ocl"}>
      <div className="fadeIn-ocl">
        <OurCaseList />
      </div>
    </FadeIn>
  </Layout>
)

export default IndexPage
