import React from 'react'

import {
  SectionSeparatorContainer,
  SectionSeparatorAccent
} from './section-separator.styled'

const SectionSeparator = (props) => {
  return (
    <div className="position-relative">
      <SectionSeparatorContainer {...props} />
      <SectionSeparatorAccent {...props} />
    </div>
  )
}

export default SectionSeparator
