import React, { Component } from "react"
import PropTypes from "prop-types"
import ImageRenderer from "../ImageRenderer/ImageRenderer"

import "./TextContent.scss"

class TextContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      header: props.header,
      text: props.text,
    }
  }

  render() {
    const { header, text } = this.state
    return (
      <React.Fragment>
        <section className="text-content">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 mb-lg-0">
								<div className="d-flex justify-content-center mb-5">
								<ImageRenderer
                  alt="Logo"
                  width="100px"
                  filename="static/product-auto-logo.png"
                />
								</div>
                <h2 className="mb-0 mb-5 fs-8 primary-font text-center">{header}</h2>
                <p className="mb-0 px-lg-5 text-light fs-4 lh-40p xx">
                  {text}
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}
TextContent.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
}

export default TextContent
