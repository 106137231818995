import * as React from 'react'

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'

const styles = require('./GoogleMapStyles.json')
const mapMarker = require('./marker.svg')

const GoogleMapComponentWithMarker = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{
        lat: props.lat,
        lng: props.lang
      }}
      defaultOptions={{
        disableDefaultUI: true,
        draggable: props.draggable,
        keyboardShortcuts: false,
        scaleControl: true,
        scrollwheel: false,
        fullscreenControl: true,
        styles: styles
      }}
    >
      <Marker
        icon={mapMarker}
        position={{
          lat: props.lat,
          lng: props.lang
        }}
      />
    </GoogleMap>
  ))
)

export default GoogleMapComponentWithMarker
