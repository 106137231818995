import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageRenderer from '../ImageRenderer/ImageRenderer';

import './IntroSlideLP.scss';

function Slot() {
	return null;
}

class IntroSlideLP extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logo: props.logo,
			title: props.title,
			subtitle: props.subtitle,
			backgroundImage: props.backgroundImage,
			keyword: props.keyword
		};
	}

	static Slot = Slot;

	render() {
		const { children } = this.props;
		const { logo, title, subtitle, backgroundImage, keyword } = this.state;
		const Slot = children ? children.props.children : null;
		const form = Slot ? 'justify-content-start contact-form' : '';

		return (
			<React.Fragment>
				<div className={`intro-slide mt-5 d-flex flex-column align-items-center w-100 ` + form}>
					{logo && (
						<div className={`intro-slide__logo ${backgroundImage ? 'mt-auto': ''}`}>
							<ImageRenderer loading="eager" alt={title} filename={logo} />
						</div>
					)}
					<div className="intro-slide__content">
						{title && (
							<h1 className="primary-font fs-10 mb-0 text-center">
								{`${title} `}
								{keyword && <span className="intro-slide__keyword blue">{keyword}</span>}
							</h1>
						)}
						{subtitle && <h3 className={`fs-6 text-light text-center font-weight-normal mt-3 ${backgroundImage ? 'mb-5': 'mt-2'}`}>{subtitle}</h3>}
					</div>
					{backgroundImage && (
						<div className="intro-slide__background mt-auto">
							<ImageRenderer loading="eager" alt={title} filename={backgroundImage} />
						</div>
					)}
					{Slot && <React.Fragment>{Slot}</React.Fragment>}
				</div>
			</React.Fragment>
		);
	}
}

IntroSlideLP.propTypes = {
	logo: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	backgroundImage: PropTypes.string,
	keyword: PropTypes.string
};

IntroSlideLP.defaultProps = {
	logo: null,
	title: null,
	subtitle: null,
	backgroundImage: null,
	keyword: null
};

export default IntroSlideLP;
