import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageRenderer from '../ImageRenderer/ImageRenderer'

import './ProductFeatureItem.scss'

class ProductFeatureItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      feature: this.props.feature
    }
  }

  render () {
    const { feature } = this.state

    return (
      <React.Fragment>
        <div className="featured-item col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="featured-item__wrapper">
            <ImageRenderer
              className="featured-item__wrapper__logo"
              alt={feature.headline}
              filename={feature.icon}
            />
          </div>
          <h1 className="featured-item__headline fs-5 text-center">
            {feature.headline}
          </h1>
          <p className="featured-item__text text-light fs-4 lh-28p text-justify px-2">
            {feature.text}
          </p>
        </div>
      </React.Fragment>
    )
  }
}

ProductFeatureItem.propTypes = {
  feature: PropTypes.shape({
    logo: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string
  })
}

export default ProductFeatureItem
