import React from 'react'
import CtaCard from '../CtaCard/CtaCard'
import SubscribeFormLP from '../SubscribeFormLP/SubscribeFormLP'
import { Link } from 'gatsby'

import './Cta.scss'

export default class extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    const martin = `Martin Albeck-Stender`

    return (
      <section className="footer-cta">
        <div className="footer-cta__dots" />
        <div className="footer-cta__clip-bg" />
        <div className="container">
          <div className="row">
            <CtaCard
              subtitle="Kom i kontakt med os"
              text={martin}
              cssClass="bg-secondary"
              headerClass="fs-7"
            >
              <CtaCard.Header>
                <h3 className="fs-4 mt-3">Head of Sales</h3>
                <div className="mt-5">
                  <span className="fs-5">Mobil: </span>
                  <a className="fs-5" href="tel:+4520781488">
                    +45 20 78 14 88
                  </a>
                </div>
                <div className="mt-1">
                  <span className="fs-5">E-mail: </span>
                  <a className="fs-5" href="mailto:mas@adhub.dk">
                    mas@adhub.dk
                  </a>
                </div>
                <div className="mt-1">
                  <span className="fs-5">Web: </span>
                  <a className="fs-5" href="https://adhub.dk">
                    adhub.dk
                  </a>
                </div>
              </CtaCard.Header>

              <CtaCard.Body>
               
                 <div className="btn btn-light mt-3 color-secondary color-white">
                  <a className="fs-3" href="mailto:mas@adhub.dk">
               
                  Kontakt Os
                  </a>
                </div>               
              </CtaCard.Body>
            </CtaCard>

            <CtaCard
              subtitle="Vi kontakter dig"
              text="Udfyld dine kontaktoplysninger, så ringer vi til dig"
              cssClass="bg-tertiary"
              headerClass="fs-6 mb-4"
            >
              <CtaCard.Header />
              <CtaCard.Body>
                <SubscribeFormLP />
              </CtaCard.Body>
            </CtaCard>
          </div>
        </div>
      </section>
    )
  }
}
