/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../assets/styles/index.scss"

import Header from "../components/Header/Header"
import FooterCta from "../components/FooterCta/FooterCta"
import OurOffices from "../components/OurOffices/OurOffices"
import CookieBanner from "../components/cookie-banner/cookie-banner.component"
import Footer from "../components/Footer/Footer"
import FadeIn from "../components/FadeIn/FadeIn"
import AdBlockDetect from "../components/adblock-detect/adblock-detect.component"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        {children}

        <FadeIn element={".fadeIn-footerCta"}>
          <div className="fadeIn-footerCta">
            <FooterCta />
          </div>
        </FadeIn>

        <FadeIn element={".fadeIn-ourOffices"}>
          <div className="fadeIn-ourOffices">
            <OurOffices />
          </div>
        </FadeIn>
      </main>
      <Footer />

      <CookieBanner />
      <AdBlockDetect />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
