import styled, { css } from 'styled-components'

const leftStyles = css`
  border-color: #c66da1;

  &:hover {
    border-color: #c66da1;
    box-shadow: 0px 10px 40px rgba(27, 52, 98, 0.2);
  }
`
const rightStyles = css`

  &:hover {
    border-color: #23a5de;
    box-shadow: 0px 10px 40px rgba(27, 52, 98, 0.2);
  }
`

const getStyles = (props) => {
  return props.align === 'left-column' ? leftStyles : rightStyles
}


export const InfoBoxContainer = styled.div.attrs(props =>({
  className: `info-boxes__box w-100 d-flex flex-column justify-content-center align-items-center initial-border ${props.align}`
}))`
  ${getStyles}
`
