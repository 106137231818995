import React from 'react'
import './CtaCard.scss'

function Header () {
  return null
}

function Body () {
  return null
}

export default class extends React.Component {
  constructor(props) {
    super(props)
  }

  static Header = Header
  static Body = Body

  render () {
    const { children } = this.props

    const header = children.find((child) => child.type === Header)
    const body = children.find((child) => child.type === Body)

    const { subtitle, text, cssClass, headerClass } = this.props

    return (
      <div className="col-12 col-md-12 col-lg-6 d-flex color-white">
        <div
          className={`footer-card d-flex flex-column w-100 bg-primary ${cssClass}`}
        >
          <div className="footer-card__content h-100 d-flex flex-column">
            <div className="fs-2 text-upper color-primary">{subtitle}</div>
            <h4
              className={`footer-card__header pre-wrap pb-0 lh-56p ${headerClass}`}
            >
              {text}
            </h4>
            {header ? header.props.children : null}
            {body ? (
              <div className="footer-card__slot d-flex">
                {body.props.children}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
