import React from 'react'
import ImageRenderer from '../ImageRenderer/ImageRenderer'
import SectionSeparator from '../section-separator/section-separator.component'

import './remote-contact.styles.scss'

class RemoteContact extends React.Component {
  componentDidMount () {
    document.querySelector('.fadeIn-footerCta').classList.add('bg-accent')
  }

  render () {
    return (
      <React.Fragment>
        <SectionSeparator clipLeft />
        <section className="remote-contact bg-accent">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                <h2 className="fs-7 remote-contact__title">
                We know everyone needs a comfortable place to work from. Since the beginning, we proudly promoted work from home.
                </h2>
                <p className="fs-4 mt-4">
                  Drop us a line at{' '}
                  <a
                    className="color-l-primary"
                    href="mailto:hello@adhub.dk"
                  >
                    hello@adhub.dk
                  </a>
                </p>
              </div>
              <div className="col-12 col-lg-6 d-flex justify-content-center">
                <ImageRenderer
                  alt="Logo"
                  className="remote-contact__image"
                  filename="remote.png"
                />
              </div>
            </div>
          </div>
        </section>
        <SectionSeparator />
      </React.Fragment>
    )
  }
}

export default RemoteContact
