import React from 'react';
import { Link } from 'gatsby';

import './DropdownList.scss';

export default class DropdownList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			links: props.links,
			dropdownDiff: props.dropdownDiff
		};
	}
	componentDidMount() {
    document.addEventListener('click', this.closeDropdown);
    window.addEventListener('scroll', this.closeDropdown);
    window.addEventListener('resize', this.closeDropdown);
	}

	componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdown);
    window.removeEventListener('scroll', this.closeDropdown);
    window.removeEventListener('resize', this.closeDropdown);
	}

	closeDropdown = () => {
		this.props.isOpen(false);
	};

	render() {
		const { dropdownDiff } = this.state;

		return (
			<ul className="dropdown__list" style={{ right: '-' + dropdownDiff + 'px' }}>
				{this.props.links.map((link, i) => {
					return (
						<li key={i}>
							<Link className="fs-3 color-primary" to={link.url}>
								{link.name}
							</Link>
						</li>
					);
				})}
			</ul>
		);
	}
}
