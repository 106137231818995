import React, { Component } from "react"
import GoogleMapComponentWithMarker from "../GoogleMapComponentWithMarker/GoogleMapComponentWithMarker.component"

import "./MapTabs.scss"

class MapTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 1,
      draggable: true,
    }
  }

  tabChange(tab) {
    this.setState({ tab: tab })
  }

  render() {
    const { tab, draggable } = this.state
    const tab1 = tab === 1 ? "active" : ""
    const tab2 = tab === 2 ? "active" : ""

    const mapContainerStyles = {
      width: "100%",
      height: "900px",
    }
    const mobileMapContainerStyles = {
      width: "100%",
      height: "380px",
    }

    return (
      <React.Fragment>
        <section className="map-tabs">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h2 className="map-tabs__header fs-8 primary-font ">
                  Our Offices
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-3 d-flex">
                <div className="d-flex flex-column w-100">
                  <div
                    className={
                      `map-tabs__item d-flex flex-column justify-content-center mb-4 mb-lg-0 ` +
                      tab1
                    }
                    onClick={() => this.tabChange(1)}
                  >
                    <h3 className="fs-7 color-primary">Copenhagen</h3>
                    <p className="fs-4 mb-2">Valby Langgade 43</p>
                    <p className="fs-4 mb-2">2500 Valby</p>
                    <p className="fs-4 mb-2"><a href="tel:+4561666880">+45 61 66 68 80</a></p>
                    <p className="fs-4 mb-2"><a href="mailto:hello@adhub.dk">hello@adhub.dk</a></p>
                  </div>
                  <div className="d-lg-none mb-5">
                    <div style={mobileMapContainerStyles}>
                      <GoogleMapComponentWithMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDFqkLnIv96G_X2_CINfVnal_0v6w3X_O0"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        lat={55.666525}
                        lang={12.5155224}
                        draggable={draggable}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      `map-tabs__item d-flex flex-column justify-content-center mb-4 mb-lg-0 ` +
                      tab2
                    }
                    onClick={() => this.tabChange(2)}
                  >
                    <h3 className="fs-7 color-primary">Bucharest</h3>
                    <p className="fs-4 mb-2">Strada Sfinților nr. 1</p>
                    <p className="fs-4 mb-2">021063 Sector</p>                    
                    <p className="fs-4 mb-2"><a href="mailto:hello@adhub.dk">hello@adhub.dk</a></p>
                  </div>
                  <div className="d-lg-none mb-5">
                    <div style={mobileMapContainerStyles}>
                      <GoogleMapComponentWithMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDFqkLnIv96G_X2_CINfVnal_0v6w3X_O0"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        lat={44.4351709}
                        lang={26.1051523}
                        draggable={draggable}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-9 pl-lg-0 d-none d-lg-block">
                <div
                  className="map-tabs__map"
                  style={{ display: tab === 1 ? "block" : "none" }}
                >
                  <div style={mapContainerStyles}>
                    <GoogleMapComponentWithMarker
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDFqkLnIv96G_X2_CINfVnal_0v6w3X_O0"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      lat={55.666525}
                      lang={12.5155224}
                      draggable={draggable}
                    />
                  </div>
                </div>
                <div
                  className="map-tabs__map"
                  style={{ display: tab === 2 ? "block" : "none" }}
                >
                  <div style={mapContainerStyles}>
                    <GoogleMapComponentWithMarker
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDFqkLnIv96G_X2_CINfVnal_0v6w3X_O0"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      lat={44.4351709}
                      lang={26.1051523}
                      draggable={draggable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default MapTabs
