import React, { Component } from "react"
import PropTypes from "prop-types"
import IntroSlideLP from "../IntroSlideLP/IntroSlideLP"
import ContactForm from "../../components/ContactForm/ContactForm"

import "./IntroHeroLP.scss"
import { Link } from "gatsby"

class IntroHeroLP extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slide: props.slide,
      form: props.form,
    }
  }

  componentDidMount() {
    this.setState({ slideHeight: this.refs.slide.clientHeight - 50 })
  }

  render() {
    const { slide, form } = this.state

    return (
      <React.Fragment>
        <section className="intro-hero bg-primary color-white">
          <div className="container" ref="slide">
            <div className="row ">
              <div className="col-12 col-lg-12">
                <IntroSlideLP
                  logo={slide.logo}
                  title={slide.title}
                  subtitle={slide.subtitle}
                  backgroundImage={slide.backgroundImage}
                  keyword={slide.keyword}
                >
                  {form && (
                    <IntroSlideLP.Slot>
                      <ContactForm />
                    </IntroSlideLP.Slot>
                  )}
                </IntroSlideLP>
              </div>
            </div>
          </div>
        </section>

        <div
          className="graphic position-relative"
          style={{ minHeight: "60px" }}
        >
          <div className="graphic__infographic" />
        </div>

        <div
          className="mt-0 mb-3 text-center my-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "120px" }}
        >
          <div>
            <a
              className="btn btn-primary-accent color-gold fs-4 font-semi-bold"
              href={slide.link}
              target="_blank"
            >
              Book en demo
            </a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

IntroHeroLP.propTypes = {
  minHeight: PropTypes.string,
  slide: PropTypes.object,
  form: PropTypes.bool,
}

IntroHeroLP.defaultProps = {
  slide: {
    logo: null,
    title: null,
    subtitle: null,
    backgroundImage: null,
    keywords: null,
    link: null,
  },
  form: false,
}

export default IntroHeroLP
