import React from 'react';
import './FooterCtaCard.scss';

function Body() {
	return null;
}

export default class FooterCtaCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subtitle: props.subtitle,
			text: props.text,
			cssClass: props.class || ''
		};
	}

	static Body = Body;

	render() {
		const { children } = this.props;
		const { subtitle, text, cssClass } = this.state;

		return (
			<div className="col-12 col-md-12 col-lg-6 d-flex color-white">
				<div className={`footer-card d-flex flex-column w-100 bg-primary ${cssClass}`}>
					<div className="footer-card__content d-flex flex-column">
						<div className="fs-2 text-upper color-primary">{subtitle}</div>
						<h4 className="footer-card__header fs-7 lh-56p">{text}</h4>
					</div>
					<div className="footer-card__slot d-flex">{children ? children.props.children : null}</div>
				</div>
			</div>
		);
	}
}
