import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageRenderer from '../ImageRenderer/ImageRenderer'
import { Link } from 'gatsby'

import './TextImageLP.scss'

class TextImageLP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heading: this.props.section.heading,
      keyword: this.props.section.keyword,
      keywordType: this.props.section.keywordType,
      content: this.props.section.content,
      content2: this.props.section.content2,
      content3: this.props.section.content3,
      imageUrl: this.props.section.imageUrl,
      leftAlign: this.props.leftAlign,
      logo: this.props.logo,
      type: this.props.type
    }
  }

  render() {

    const {
      heading,
      keyword,
      keywordType,
      content,
      content2,
      content3,
      imageUrl,
      leftAlign,
      type,
      logo
    } = this.state
    
    const rowReverse = leftAlign ? 'row row-reverse' : 'row'

    return (
      <React.Fragment>
        <section className="text-image">
          <div className="container">
            <div className={rowReverse}>
              <div className="col-12 col-md-12 col-lg-6 d-flex mb-5 mb-lg-0">

                { type === 'highlight' && 
                  <div className="text-image__content d-flex flex-column justify-content-center">
                    <h2 className="fs-7 lh-56p">
                      <span className={`text-image__content__keyword ` + keywordType}>
                        {keyword}
                      </span>                     
                      &nbsp;
                      <span className="">
                        {heading}
                      </span>

                    </h2>
                    <p className="mb-3 fs-4 lh-40p font-weight-normal">
                      {content}
                    </p>

                    <p className="mb-3 fs-4 lh-40p font-weight-normal">
                      {content2}
                    </p>

                    <p className="mb-0 fs-4 lh-40p font-weight-normal">
                      {content3}
                    </p>

                  </div>
              }

              { type === 'btn' &&
                <div className="text-image__content d-flex flex-column justify-content-center">

                  <div className="d-flex align-items-center">
                    {logo.length > 0 &&
                      <div className="text-image__content__logo mr-2">
                        <ImageRenderer alt={keyword} filename={logo}/>
                      </div>
                    }
                    <span className="text-upper fs-3">
                      {keyword}
                    </span>
                  </div>
                
                  <h2 className="fs-7 lh-56p mt-4">
                    {heading}
                  </h2>

                  <p className="mb-0 fs-4 lh-40p font-weight-normal">
                    {content}
                  </p>
                  <div className="text-image__btn d-flex">
                    <Link 
                      className="btn btn-secondary color-white fs-3 color-white" 
                      to="/contact"
                    >
                      Read Case Study
                    </Link>
                  </div>
                </div>

              }

              </div>
              <div className="col-12 col-md-12 col-lg-6 d-flex">
                <div className="text-image__background">
                  <ImageRenderer alt="case-report" filename={imageUrl} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

TextImageLP.propTypes = {
  section: PropTypes.shape({
    heading: PropTypes.string,
    keyword: PropTypes.string,
    keywordType: PropTypes.oneOf(['blue', 'green', 'salmon']),
    content: PropTypes.string,
    content2: PropTypes.string,
    content3: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  leftAlign: PropTypes.bool,
  type: PropTypes.oneOf(['btn', 'highlight']),
  logo: PropTypes.string
}

TextImageLP.defaultProps = {
  leftAlign: false,
  type: 'highlight',
  logo: ''
}

export default TextImageLP;