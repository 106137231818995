import React from "react"
import ImageRenderer from "../ImageRenderer/ImageRenderer"
import SectionSeparator from '../../components/section-separator/section-separator.component'
// import Logo from "-!svg-react-loader?props[]=className:!../../images/svg/test.inline.svg";
import "./HeroSlideLP.scss"

export default class HeroSlideLP extends React.Component {
  onScrollDown = () => {
    //scroll by the window height minus the menu and or the separator
    if (window.pageYOffset === 0) {
      window.scrollBy({
        top: window.innerHeight - 10,
        left: 0,
        behavior: "smooth",
      })
    } else {
      window.scrollBy({
        top: (window.innerHeight - window.pageYOffset) - 10,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  render() {
    return (
      <React.Fragment>
      <SectionSeparator bgColor="#1c3360" clipLeft />
        <section className="hero-slide-lp d-flex bg-primary">
          <div className="container d-flex flex-column">
            <div className="row flex-grow-1 align-items-center position-relative">
              <div className="col-12 col-xl-7 d-flex flex-column justify-content-center z-index-3 mt-auto mt-md-0">
                <h1 className="fs-7 mb-5 primary-font font-bold color-white">
                Adhub har en skræddersyet algoritme til hver enkelt kunde...
                </h1>

                <p className="fs-6 lh-42p text-light">
                Når I som kunde onboardes på Adhub sker det enkelt og nemt.                  
                </p>

                <p className="fs-6 lh-42p text-light">
                Vores supportafdeling sørger for at I får jeres egen unikke algoritme, tilpasset til jeres forretning.​
                </p>

                <p className="fs-6 lh-42p text-light">
                Vi sørger også for at designe jeres digitalemediebannere til brug for annoncering.​ 
                </p>

                <p className="fs-6 lh-42p text-light">
                På den måde opnår I de bedste resultater.​
                </p>
              </div>
              <div className="hero-slide-lp__image-container d-flex justify-content-center z-index-2 mb-auto mb-md-0">
                <div className="hero-slide-lp__image">
                  <ImageRenderer alt="Logo" width="700px" loading="eager" filename="home-bg-header.png" />
                </div>
              </div>

           
            </div>
          </div>
        </section>
        <SectionSeparator bgColor="#1c3360" clipRight />
      </React.Fragment>
    )
  }
}
