import React from 'react';
import FooterCtaCard from '../FooterCtaCard/FooterCtaCard';
import SubscribeForm from '../SubscribeForm/SubscribeForm';
import { Link } from 'gatsby';

import './FooterCta.scss';

export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<section className="footer-cta">
				<div className="footer-cta__dots" />
				<div className="footer-cta__clip-bg" />
				<div className="container">
					<div className="row ">
						<FooterCtaCard
							subtitle="Get in touch with us"
							text="We have the platform you need to create efficient online and offline marketing automation"
							class="bg-secondary"
						>
							<FooterCtaCard.Body>
								<Link className="btn btn-light color-secondary color-white" to="/contact">
									Contact Us
								</Link>
							</FooterCtaCard.Body>
						</FooterCtaCard>
						<FooterCtaCard
							subtitle="Get the latest"
							text="Stay up to date with the latest in MarTech"
							class="bg-tertiary"
						>
							<FooterCtaCard.Body>
								<SubscribeForm />
							</FooterCtaCard.Body>
						</FooterCtaCard>
					</div>
				</div>
			</section>
		);
	}
}
