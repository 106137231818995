import React from 'react';
import DropdownList from '../DropdownList/DropdownList';

import './Dropdown.scss';

export default class Dropdown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDropdownOpen: false,
			name: props.name,
			links: props.links,
			dropdownWidth: 0
		};
	}
	componentDidMount() {
		// this.setState({ dropdownWidth: (180 - this.refs.dropdown.clientWidth) / 2 })
	}

	onDropdownClick = () => {
		const { isDropdownOpen } = this.state;
		this.setState({ isDropdownOpen: !isDropdownOpen });
	};

	onDropdownLeave = (payload) => {
		this.setState({ isDropdownOpen: payload });
	};

	render() {
		const { isDropdownOpen, dropdownWidth } = this.state;

		return (
			<div className="dropdown" onClick={this.onDropdownClick}>
				<div className="dropdown__text fs-4 font-semi-bold">
					<span>{this.state.name}</span>
				</div>
				{isDropdownOpen && (
					<DropdownList isOpen={this.onDropdownLeave} links={this.state.links} dropdownDiff={dropdownWidth} />
				)}
			</div>
		);
	}
}
