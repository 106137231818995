import gsap from "gsap"

export const fadeIn = el => {
  gsap.to(el, {
    opacity: 1,
    y: 0,
    ease: "power4.out",
    stagger: {
      amount: 0.3,
    },
  })
}

export const fadeOut = el => {
  gsap.to(el, {
    opacity: 0,
    y: 60,
    ease: "power4.out",
  })
}
