import React, { useRef, useEffect } from 'react';
import { useIntersection } from 'react-use';
import { fadeOut, fadeIn } from '../Animations';

const FadeIn = (props) => {
  let section = useRef(null);
	const element = props.element
	const threshold = props.threshold

	const intersection = useIntersection(section, {
		root: null,
		rootMargin: '0px',
		threshold: threshold
	});

  useEffect(() => intersection && intersection.intersectionRatio < threshold ? fadeOut(element) : fadeIn(element))
	

	return (
		<React.Fragment>
			<div ref={section} key={props.name}>
				{props.children}
			</div>
		</React.Fragment>
	);
};

FadeIn.defaultProps = {
	threshold: 0.2
}

export default FadeIn;
