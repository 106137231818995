import React from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"
import IntroHero from "../components/IntroHero/IntroHero"
import MapTabs from "../components/MapTabs/MapTabs"
import FadeIn from "../components/FadeIn/FadeIn"
import RemoteContact from "../components/remote-contact/remote-contact.component"

const slide = {
  title: "Contact Us",
}

const ContactPage = () => (
  <Layout>
    <SEO title="Contact us" description={`Reach out to us`} />

    <IntroHero slide={slide} form />

    <FadeIn element={".fadeIn-mapTabs"}>
      <div className="fadeIn-mapTabs">
        <MapTabs />
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-remoteContact"}>
      <div className="fadeIn-remoteContact">
        <RemoteContact />
      </div>
    </FadeIn>
  </Layout>
)

export default ContactPage
