import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProductFeatureItem from '../ProductFeatureitem/ProductFeatureItem'

import './ProductFeatures.scss'

class ProductFeatures extends Component {

  render () {
    const { headline, features } = this.props

    return (
      <React.Fragment>
        <section className="product-features bg-accent">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h2 className="primary-font fs-8 font-weight-normal mb-0 text-center">
                  {headline ? headline : null}
                </h2>
              </div>
            </div>
            <div className="row product-features__items">
              {features.map((feature, index) => {
                return <ProductFeatureItem key={index} feature={feature} />
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

ProductFeatures.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      headline: PropTypes.string,
      text: PropTypes.string
    })
  )
}

ProductFeatures.defaultProps = {
  features: []
}

export default ProductFeatures
