import React from 'react';

import Layout from '../layout/layout';
import IntroHero from '../components/IntroHero/IntroHero';
import TextContent from '../components/TextContent/TextContent';
import ContactCard from '../components/ContactCard/ContactCard';
import FadeIn from '../components/FadeIn/FadeIn';
import SectionSeparator from '../components/section-separator/section-separator.component'
import SEO from '../components/seo';

const slide = {
	title: ' Experts in',
	keyword: 'MarTech'
};

const textContent = {
	header: 'MarTech meets business logic',
	text:
		'Adhub consist of a group of tech enthusiasts, constantly searching for emerging technologies and management techniques so you can achieve your business goals through Martech software and automation strategies. In a nutshell: Adhub is the passionate business partner providing solutions for your always growing business.'
	};

const AboutPage = () => (
	<Layout>
		<SEO title="About" description={`MarTech meets business logic`} />
		<IntroHero slide={slide} />

		<FadeIn element={'.fadeIn-1'}>
			<div className="fadeIn-1">
				<TextContent header={textContent.header} text={textContent.text} />
			</div>
		</FadeIn>

		<FadeIn element={'.fadeIn-2'}>
			<div className="fadeIn-2">
				<SectionSeparator clipLeft />
				<ContactCard />
				<SectionSeparator clipRight />
			</div>
		</FadeIn>
	</Layout>
);

export default AboutPage;
