import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntroSlide from '../IntroSlide/IntroSlide';
import ContactForm from '../../components/ContactForm/ContactForm';

import './IntroHero.scss';

class IntroHero extends Component {
	constructor(props) {
		super(props);

		this.state = {
			slide: props.slide,
			form: props.form
		};
	}

	componentDidMount() {
		this.setState({ slideHeight: this.refs.slide.clientHeight - 50 });
	}

	render() {
		const { slide, form } = this.state;

		return (
			<React.Fragment>
				<section className="intro-hero bg-primary color-white">
					<div className="container" ref="slide">
						<div className="row ">
							<div className="col-12 col-lg-12">
								<IntroSlide
									logo={slide.logo}
									title={slide.title}
									subtitle={slide.subtitle}
									backgroundImage={slide.backgroundImage}
									keyword={slide.keyword}
								>
									{form && (
										<IntroSlide.Slot>
											<ContactForm />
										</IntroSlide.Slot>
									)}
								</IntroSlide>
							</div>
						</div>
					</div>
				</section>
				<div className="graphic position-relative" style={{ minHeight: '50px' }}>
					<div className="graphic__infographic" />
				</div>
			</React.Fragment>
		);
	}
}

IntroHero.propTypes = {
	minHeight: PropTypes.string,
	slide: PropTypes.object,
	form: PropTypes.bool
};

IntroHero.defaultProps = {
	slide: {
		logo: null,
		title: null,
		subtitle: null,
		backgroundImage: null,
		keywords: null
	},
	form: false
};

export default IntroHero;
