import React, { Component } from "react"
import Recaptcha from "react-recaptcha"

import "./ContactForm.scss"

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      interest: "",
      comment: "",
      recaptcha: "",
      errors: {
        name: "",
        email: "",
        // interest: "",
        recaptcha: "",
      },
    }
  }

  handleFormChange = event => {
    const { name, value } = event.target
    let errors = this.state.errors
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    )

    switch (name) {
      case "name":
        errors.name = value.length < 3 ? "Your forgot to enter your name!" : ""
        break
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "You email is not valid!"
        break
      // case "interest":
      //   errors.interest = value.length < 1 ? "What are you interested in?" : ""
      //   break
      case "recaptcha":
        errors.recaptcha =
          value.length < 1 ? "Complete the puzzle challenge" : ""
        break
      default:
        break
    }

    this.setState({ errors, [name]: value })
  }

  validateForm = errors => {
    let valid = true
    Object.values(errors).forEach(val => val.length > 0 && (valid = false))
    return valid
  }

  handleSubmit = event => {
    event.preventDefault()
    this.handleFormChange({
      target: {
        name: "name",
        value: this.state.name,
      },
    })
    this.handleFormChange({
      target: {
        name: "email",
        value: this.state.email,
      },
    })
    // this.handleFormChange({
    //   target: {
    //     name: "interest",
    //     value: this.state.interest,
    //   },
    // })
    this.handleFormChange({
      target: {
        name: "recaptcha",
        value: this.state.recaptcha,
      },
    })
    const { name, email, comment } = this.state

    const body = {
      body: `Name: ${name} from Adhub.dk and asked: ${comment}`,
      customerEmail: email,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }

    if (this.validateForm(this.state.errors)) {
      fetch(
        "https://estateserver.adhub.dk/api/services/app/Contactform/Mail",
        requestOptions
      ).then(r => {
        if (r.ok) {
          this.contactForm.reset()
          this.recatpchaEl.reset()
          this.statusMessage.style.display = "block"
          setTimeout(() => {
            this.statusMessage.style.display = "none"
          }, 7000)
        }
      })
    }
  }

  recaptchaInit = () => {
    return this.setState({ errors: { ...this.state.errors, recaptcha: "" } })
  }

  recaptchaVerfication = res => {
    return this.setState({
      recaptcha: "Recaptcha success",
    })
  }

  // onSubmit={this.handleSubmit}
  render() {
    const selectOptions = [
      { value: "Adhub Estate", label: "Adhub Estate" },
      // { value: "Adhub Auto", label: "Adhub Auto" },
      // { value: "Adhub Auction", label: "Adhub Auction" },
    ]
    const { errors } = this.state

    return (
      <React.Fragment>
        <div className="d-flex flex-column align-items-center w-100">
          <form
            className="adhub-form box-shadow bg-white"
            onSubmit={this.handleSubmit}
            ref={el => (this.contactForm = el)}
            onChange={this.handleFormChange}
          >
            <div className="adhub-form__field">
              <h2 className="adhub-form__field__title fs-7 color-primary">
                Reach out
              </h2>
            </div>

            <div className="adhub-form__field">
              <label className="d-flex" htmlFor="name">
                <span className="adhub-form__field__label">Name</span>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                />
              </label>
            </div>
            <div className="adhub-form__name-error">
              {errors.name.length > 0 && <span>{errors.name}</span>}
            </div>
            <div className="adhub-form__field">
              <label className="d-flex" htmlFor="email">
                <span className="adhub-form__field__label">Email</span>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your E-mail"
                />
              </label>
            </div>
            <div className="adhub-form__email-error">
              {errors.email.length > 0 && <span>{errors.email}</span>}
            </div>
            {/*<div className="adhub-form__field">
              <Select
                className="w-100"
                styles={SelectStyles}
                placeholder="Interested In"
                onChange={evt =>
                  this.handleFormChange({
                    target: {
                      name: "interest",
                      value: evt.value,
                    },
                  })
                }
                getOptionLabel={option => `${option.label}`}
                options={selectOptions}
              />
            </div>
            <div className="adhub-form__interest-error">
              {errors.interest.length > 0 && <span>{errors.interest}</span>}
            </div>*/}
            <div className="adhub-form__field">
              <label className="d-flex" htmlFor="comment">
                <span className="adhub-form__field__label">Comment</span>
                <textarea
                  rows="3"
                  id="comment"
                  placeholder="Message"
                  name="comment"
                  style={{ resize: "none" }}
                />
              </label>
            </div>
            <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
              <Recaptcha
                ref={el => (this.recatpchaEl = el)}
                sitekey="6LeVDaUZAAAAADIg4MDauKcOnTU3japVRqFHsodY"
                render="explicit"
                onloadCallback={this.recaptchaInit}
                verifyCallback={this.recaptchaVerfication}
              />
              <div className="adhub-form__email-error">
                {errors.recaptcha.length > 0 && <span>{errors.recaptcha}</span>}
              </div>
            </div>
            <div className="adhub-form__submit d-flex flex-column align-items-center justify-content-center">
              <input
                className="btn btn-primary border-0"
                type="submit"
                value="Submit"
              />
              <div
                className="mt-4 text-center"
                style={{ transition: "all .3 ease", display: "none" }}
                ref={el => (this.statusMessage = el)}
              >
                <h4 className="fs-4 color-l-secondary ">
                  Thank you for reaching out! We will get back to you as soon as
                  possible.
                </h4>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default ContactForm
