import React from "react"

import Layout from "../../layout/layout"
import IntroHero from "../../components/IntroHero/IntroHero"
import ContentSteps from "../../components/ContentSteps/ContentSteps"
import ImageSlider from "../../components/ImageSlider/ImageSlider"
import MetricWidgets from "../../components/MetricWidgets/MetricWidgets"
import FadeIn from "../../components/FadeIn/FadeIn"
import SEO from "../../components/seo"

const slide = {
  logo: "case-images/nordicals-logo-white.png",
  title: "Nordicals",
  subtitle: "Greater business goals made possible with effective ad campaigns.",
}
const imageSlides = [
	{
		image: 'sliders/remarketing-slider.png'
	},
	{
		image: 'sliders/datepicker-slider.png'
	},
	{
		image: 'sliders/interest-group-slider.png'
	}
]

const Estate = () => (
  <Layout>
    <SEO title="Adhub Estate" description={`Adhub`} />
    
    <IntroHero slide={slide} />

    <FadeIn element={".fadeIn-1"}>
      <div className="fadeIn-1">
        <ContentSteps
          step="01"
          title="Background"
          text={`Nordicals is one of the biggest real estate companies in Denmark and has several small real estate agencies in most of the major areas. Having properties across different areas, their need was to have specific targeting based on interest groups and location on all delivery networks. 
      `}
        />
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-2"}>
      <div className="fadeIn-2">
        <ContentSteps
          backgroundColor="#F6F9FC"
          step="02"
          title="Solution"
          text={`End to end automatic lead generation. We linked our platform to the client's database of properties and withdrew all relevant information (pictures, address, postal code, description) then we created an easy to use user interface for real estate agents to create streamlined campaigns based on unified data.
      `}
        >
          <ContentSteps.Slot>
            <ImageSlider slides={imageSlides} header="Adhub Automation" />
          </ContentSteps.Slot>
        </ContentSteps>
      </div>
    </FadeIn>

    <FadeIn element={".fadeIn-3"}>
      <div className="fadeIn-3">
        <ContentSteps
          step="03"
          title="Results"
          text={`We are proud to say that our partnership led to incredible results.`}
        >
          <ContentSteps.Slot>
            <MetricWidgets clicks="34,6M" views="649K" />
            {/* <ImageSlider autoplay={true} slides={imageSlides} arrows={false} /> */}
          </ContentSteps.Slot>
        </ContentSteps>
      </div>
    </FadeIn>
  </Layout>
)

export default Estate
