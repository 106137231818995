import React from 'react';
import CaseCards from '../CaseCards/CaseCards';

import './OurCaseList.scss';

export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			cards: [
				{
					logo: 'case-images/edc-logo.png',
					logoWidth: '100px',
					caseName: 'Real Estate',
					text: 'Adhub techstack integrated into EDC core business to automate cross platform property campaigns',
					// linkUrl: '/cases/estate'
				},
				{
					logo: 'case-images/omd-logo.png',
					logoWidth: '261px',
					caseName: 'Workflow automation',
					text: 'Adhub Print integrated with OMD business platform to automate print campaigns with Danish publishers',
					// linkUrl: '/cases/auto'
				},
				{
					logo: 'case-images/nordicals-logo.png',
					logoWidth: '257px',
					caseName: 'Commercial real estate',
					text: 'Adhub Estate configured for commercial real estate agents',
					//linkUrl: '/cases/estate'
				}
			]
		};
	}

	render() {
		const { cards } = this.state;

		return (
			<section className="our-case-list">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2 className="our-case-list__header fs-8 primary-font font-bold text-dark text-center">
								Who we’ve helped
							</h2>
						</div>
					</div>

					<CaseCards cards={cards} />
				</div>
			</section>
		);
	}
}
