import React, { Component } from "react"
import { Link } from "gatsby"

import {
  CookieBannerContainer,
  CookieBannerContent,
} from "./cookie-banner.styled"

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: true,
    }
  }

  componentWillMount() {
    if (typeof localStorage !== "undefined") {
      const cookie = JSON.parse(localStorage.getItem("cookie"))

      if (cookie === null) {
        localStorage.setItem("cookie", JSON.stringify(this.state.visible))
      } else {
        this.setState({ visible: cookie })
      }
    }
  }

  onAcceptCookiePolicy = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("cookie", JSON.stringify(false))
      this.setState({ visible: false })
    } else {
      this.setState({ visible: false })
    }
  }

  render() {
    const { props } = this.props
    const { visible } = this.state

    return (
      <React.Fragment>
        {visible && (
          <CookieBannerContainer visible={visible} {...props}>
            <CookieBannerContent {...props}>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <p className="mb-0 mx-3">
                  We use cookies to improve your experience. By using our
                  website and our services, you agree to our use of cookies as
                  described in our{" "}
                  <Link className="fs-2 underline" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </p>
                <div
                  onClick={this.onAcceptCookiePolicy}
                  className="btn btn-light color-gold fs-2 mr-3 mt-3 mt-md-0 font-semi-bold"
                >
                  OK
                </div>
              </div>
            </CookieBannerContent>
          </CookieBannerContainer>
        )}
      </React.Fragment>
    )
  }
}

export default CookieBanner
