import React from "react"
import { Link } from "gatsby"
import Dropdown from "../Dropdown/Dropdown"
import MobileNav from "./MobileNav/MobileNav"

import "./navigation.scss"

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDropdownOpen: this.props.isDropdownOpen,
      initial: false,
      isMenuOpen: false,
      disabled: false,
      // productLinks: [
      //   {
      //     name: "Adhub Estate",
      //     url: "/products/estate",
      //   },
      //   {
      //     name: "Adhub Auto",
      //     url: "/products/auto",
      //   },
      //   {
      //     name: "Adhub Auction",
      //     url: "/products/auction",
      //   },
      // ],
      // caseLinks: [
      //   {
      //     name: "Case Estate",
      //     url: "/cases/estate",
      //   },
      //   {
      //     name: "Case Auto",
      //     url: "/cases/auto",
      //   },
      //   {
      //     name: "Case Auction",
      //     url: "/cases/auction",
      //   },
      // ],
    }
  }
  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })

    if (this.state.isMenuOpen) {
      document.body.style.overflow = "auto"
    } else {
      document.body.style.overflow = "hidden"
    }
  }

  disabledMenu = () => {
    this.setState({
      disabled: !this.state.disabled,
    })
    setTimeout(() => {
      this.setState({
        disabled: false,
      })
    }, 400)
  }

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const { productLinks, caseLinks, isMenuOpen } = this.state

    return (
      <React.Fragment>
        <nav className="d-lg-flex d-none">
          <ul className="menu d-flex">
            <li className="menu-item">
              <Link
                className="fs-4 font-semi-bold no-underline color-white"
                to="/"
              >
                Home
              </Link>
						</li>
						
            {productLinks && (
              <li className="menu-item">
                <Dropdown name={"Products"} links={productLinks} />
              </li>
						)}
						
            {caseLinks && (
              <li className="menu-item">
                <Dropdown name={"Cases"} links={caseLinks} />
              </li>
						)}
						
            <li className="menu-item">
              <Link
                className="fs-4 font-semi-bold no-underline color-white"
                to="/products/estate"
              >
                Adhub Estate
              </Link>
            </li>

{/*             <li className="menu-item">
              <Link
                className="fs-4 font-semi-bold no-underline color-white"
                to="/products/estatedk"
              >
                Adhub Estate DK
              </Link>
            </li> */}

            <li className="menu-item">
              <Link
                className="fs-4 font-semi-bold no-underline color-white"
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="menu-item pr-0">
              <Link
                className="fs-4 font-semi-bold no-underline color-white"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
          className="burger d-lg-none"
          onClick={this.toggleMenu}
          style={{ zIndex: "2000" }}
        >
          <div id="nav-icon3" className={isMenuOpen ? "open" : ""}>
            <span />
            <span />
            <span />
            <span />
          </div>
        </button>
        <MobileNav props={this.state} closeMenu={this.closeMenu} />
      </React.Fragment>
    )
  }
}
