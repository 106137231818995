import React, { useRef, useEffect } from 'react'
import ImageRenderer from '../ImageRenderer/ImageRenderer'
import Gsap from 'gsap'
import Navigation from '../Navigation/Navigation'
import { Link } from 'gatsby'

// import Logo from "-!svg-react-loader?props[]=className:w-2!../../images/svg/logoh.inline.svg";
import './header.scss'

const Header = () => {
  let headerRef = useRef(null)
  let spacerRef = useRef(null)
  let progress = useRef(null)
  let logo = useRef(null)
  let nav = useRef(null)
  let expanded = false

  useEffect(() => {
    progress.style.display = 'none'
    logo.style.minHeight = '120px'
    nav.style.minHeight = '120px'
    window.onscroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      let height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      let scrolled = winScroll / height * 100
      progress.style.width = scrolled + '%'

      if (window.pageYOffset > 200) {
        progress.style.display = 'block'
        if (!expanded) {
          expanded = true
          spacerRef.style.paddingTop = '120px'
          logo.style.minHeight = '80px'
          nav.style.minHeight = '80px'
          Gsap.to(headerRef, {
            duration: 0,
            css: {
              position: 'fixed',
              top: -120,
              left: 0,
              right: 0,
              zIndex: 1000
            }
          })
          Gsap.to(headerRef, {
            duration: 0.8,
            css: {
              top: 0
            },
            ease: 'power3.in'
          })
        }
      } else if (expanded) {
        expanded = false
        spacerRef.style.paddingTop = '0px'
        progress.style.display = 'none'
        logo.style.minHeight = '120px'
        nav.style.minHeight = '120px'

        Gsap.to(headerRef, {
          duration: 0,
          css: { position: 'static' }
        })
      }
    }
  })

  return (
    <React.Fragment>
      <div ref={(el) => (spacerRef = el)} />
      <header
        ref={(el) => (headerRef = el)}
        className="adhub-header bg-primary"
      >
        <div className="container">
          <div className="row flex-nowrap flex-lg-wrap ">
            <div
              ref={(el) => (logo = el)}
              className="col-10 col-lg-4 d-flex justify-content-start align-items-center"
            >
              <Link style={{ zIndex: 2000 }} to="/">
                <ImageRenderer
                  alt="Logo"
                  loading="eager"
                  className="adhub-header__logo"
                  filename="header-logo-light.png"
                />
              </Link>
            </div>
            <div
              ref={(el) => (nav = el)}
              className="col-2 col-lg-8 d-flex align-items-center justify-content-end"
            >
              <Navigation />
            </div>
          </div>
        </div>
        <div ref={(el) => (progress = el)} className="progres-bar" />
      </header>
    </React.Fragment>
  )
}

export default Header
