import React from 'react';
import { Link } from 'gatsby';
import ImageRenderer from '../ImageRenderer/ImageRenderer';

import './OurOffices.scss';

export default class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<section className="our-offices bg-primary">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-12 color-white">
							<h3 className="our-offices__header fs-1 primary-font text-light text-upper">Our Offices</h3>
						</div>
						<div className="col-12 col-lg-4 color-white">
							<div className="widget d-flex align-items-center">
								<div className="widget__icon d-flex justify-content-center align-items-center mr-4 border-transparent rounded-circle">
									<ImageRenderer
										alt="world-icon"
										width="34px"
										filename="icon-images/world-icon.png"
									/>
								</div>
								<div className="color-secondary">
									<h4 className="primary-font text-lighter fs-4 mb-1">Remote Office</h4>
									<p className="font-secondary text-lighter fs-4 lh-29p m-0 mb-2">Anywhere</p>
									<Link className="fs-3 color-secondary underline" to="/contact">
										Get in touch
									</Link>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4 color-white">
							<div className="widget d-flex align-items-center">
								<div className="widget__icon d-flex justify-content-center align-items-center mr-4 border-transparent rounded-circle">
									<span className="primary-font fs-1">DK</span>
								</div>
								<div className="text-lighter">
									<h4 className="primary-font fs-4 mb-1">Copenhagen</h4>
									<p className="font-secondary fs-4 lh-29p m-0">
										Valby Langgade 43<br />
										2500 Valby
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4 color-white">
							<div className="widget d-flex align-items-center">
								<div className="widget__icon d-flex justify-content-center align-items-center mr-4 border-transparent rounded-circle">
									<span className="primary-font fs-1">RO</span>
								</div>
								<div className="text-lighter">
									<h4 className="primary-font fs-4 mb-1">Bucharest</h4>
									<p className="font-secondary fs-4 lh-29p m-0">
										Strada Sfinților nr. 1 <br />
										021063 Sector 2
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
