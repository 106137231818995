import React from "react"
import PropTypes from "prop-types"
import ImageRenderer from "../ImageRenderer/ImageRenderer"
import { Link } from "gatsby"

import "./CaseCards.scss"

const CaseCards = props => {
  return (
    <div className="row">
      {props.cards.map((card, i) => {
        return (
          <div key={i} className="col-12 col-md-12 col-lg-4 d-flex">
            <div className="case-card bg-white w-100 d-flex flex-column">
              <div className="case-card__logo d-flex align-items-center justify-content-center">
                <ImageRenderer
                  alt="Logo"
                  width={card.logoWidth}
                  filename={card.logo}
                />
              </div>

              <p
                style={{ marginBottom: "22px" }}
                className="case-card__subtitle fs-3 text-upper"
              >
                {card.caseName}
              </p>

              <p className="case-card__content fs-6 lh-42p mb-0 color-primary">
                {card.text}
              </p>

              {card.linkUrl && (
                <div className="case-card__action d-flex w-100">
                  <Link
                    className="btn btn-primary fs-3 color-white"
                    to={card.linkUrl}
                  >
                    Read Case Study
                  </Link>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

CaseCards.defaultProps = {
  cards: [],
}

CaseCards.propTypes = {
  cards: PropTypes.array,
}

export default CaseCards
