import styled, { css } from 'styled-components'

export const CookieBannerContainer = styled.div`
  display: none;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 15px;
  justify-content: center;
  z-index: 1001;

  @media (min-width: 768px) {
    left: 30px;
    right: 30px;
  }

  ${({ visible }) => visible && css`display: flex;`};
`

export const CookieBannerContent = styled.div`
  background: #497abd;
  box-shadow: 0px 10px 40px rgba(27, 52, 98, 0.2);
  border-radius: 60px;
  padding: 20px;
  color: white;
  text-align: center;
`
