import React, { Component } from "react"
import ImageRenderer from "../../components/ImageRenderer/ImageRenderer"

import "./ContactCard.scss"

class ContactCard extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <section className="bg-accent contact-card">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12">
                <h1 className="contact-card__title fs-8 primary-font font-bold text-dark text-lg-center">
                  Meet our founders
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-5 mb-md-0">
                <div className="">
                  <h3 className="fs-8 mb-3 primary-font font-bold text-dark text-lg-center">
                    Ulrik Lendager{" "}
                  </h3>
                  <h5 className="fs-4 text-light mb-0 text-lg-center">CEO</h5>

                  <div className="contact-card__email ">
                    <p className="fs-5 mr-3 text-light">
                      Ulrik Has spent the last years developing a consultancy company working with integrated e-commerce solutions. 
                      The company was sold in 2020 to Visma, the largest IT company in Scandinavia.                       
                      Ulrik Has a Master in Engineeing an EBA and a MBA.
                    </p>
                    {/* <p className="fs-6 mb-0 mr-3 text-light text-lg-center text-italic">
                      - Some random words of wisdom from Mikkel
                    </p>
                  */}
                    {/* <p className="fs-5 mb-0 mt-4">
											Email:{' '}
											<a className="primary-font" href="mailto:ulrik@adhub.dk">
												ulrik@adhub.dk
											</a>
										</p> */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="">
                  <h3 className="fs-8 mb-3 primary-font font-bold text-dark text-lg-center">
                    Mikkel Høst
                  </h3>
                  <h5 className="fs-4 text-light mb-0 text-lg-center">CTO</h5>

                  <div className="contact-card__email">
                    <p className="fs-5 text-light">
                      With a degree in computer science, 16 years of experience in software development
                      and a background in ecommerce, B2B integrations and automation software Mikkel's knowhow
                      acts as a strong technical foundation for Adhub.                   
                    </p>

                    {/* <p className="fs-6 mb-0 mr-3 text-light text-lg-center text-italic">
                      - Some random words of wisdom from Mikkel
                    </p>
                  */}

                    {/*  <p className="fs-5 mb-0 mt-4">
                      Email:{" "}
                      <a className="primary-font" href="mailto:mikkel@adhub.dk">
                        mikkel@adhub.dk
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default ContactCard
