import React from 'react';

import Layout from '../../layout/layout';
import IntroHero from '../../components/IntroHero/IntroHero';
import ContentSteps from '../../components/ContentSteps/ContentSteps';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import MetricWidgets from '../../components/MetricWidgets/MetricWidgets';
import FadeIn from '../../components/FadeIn/FadeIn';
import SEO from '../../components/seo';

const slide = {
	logo: 'case-images/edc-logo.png',
	title: 'Auto',
	subtitle: 'End to end automattic lead generation for real estate.'
};
const imageSlides = [
	{
		image: 'sliders/agency-dashboard.png'
	},
	{
		image: 'sliders/agency-dashboard.png'
	},
	{
		image: 'sliders/agency-dashboard.png'
	},
	{
		image: 'sliders/agency-dashboard.png'
	},
	{
		image: 'sliders/agency-dashboard.png'
	}
];

const Auto = () => (
	<Layout>
		<SEO title="Adhub Estate" description={`Adhub`} />
		<IntroHero slide={slide} />

		<FadeIn element={'.fadeIn-1'}>
			<div className="fadeIn-1">
				<ContentSteps
					step="01"
					title="Background"
					text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio, euismod commodo eros placerat sed. Sed euismod tortor non risus ultricies, non bibendum tortor faucibus. Etiam sodales ipsum ut aliquet luctus. Etiam molestie quam ante, in luctus metus efficitur et. Donec vel elit tortor.
      Quisque augue magna, varius lobortis feugiat eu, suscipit sollicitudin quam. Pellentesque consectetur imperdiet massa, et placerat diam pharetra ac. Mauris malesuada fermentum velit sit amet efficitur. Fusce laoreet quam non rutrum consequat. Maecenas auctor, odio bibendum congue auctor, nisl arcu consectetur quam, eu.
      `}
				/>
			</div>
		</FadeIn>

		<FadeIn element={'.fadeIn-2'}>
			<div className="fadeIn-2">
				<ContentSteps
					backgroundColor="#F6F9FC"
					step="02"
					title="Solution"
					text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio, euismod commodo eros placerat sed. Sed euismod tortor non risus ultricies, non bibendum tortor faucibus. Etiam sodales ipsum ut aliquet luctus. Etiam molestie quam ante, in luctus metus efficitur et. Donec vel elit tortor.
      `}
				>
					<ContentSteps.Slot>
						<ImageSlider slides={imageSlides} header="Adhub Automation" />
					</ContentSteps.Slot>
				</ContentSteps>
			</div>
		</FadeIn>

		<FadeIn element={'.fadeIn-3'}>
			<div className="fadeIn-3">
				<ContentSteps
					step="03"
					title="Results"
					text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio, euismod commodo eros placerat sed. Sed euismod tortor non risus ultricies, non bibendum tortor faucibus. Etiam sodales ipsum ut aliquet luctus. Etiam molestie quam ante, in luctus metus efficitur et. Donec vel elit tortor.
      `}
				>
					<ContentSteps.Slot>
						<MetricWidgets clicks="15.121" views="24.223" comments="7.470" />
						<ImageSlider slides={imageSlides} arrows={false} />
					</ContentSteps.Slot>
				</ContentSteps>
			</div>
		</FadeIn>
	</Layout>
);

export default Auto;
