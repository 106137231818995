import React from 'react'

import Layout from '../../layout/layout'
import IntroHero from '../../components/IntroHero/IntroHero'
import TextImage from '../../components/TextImage/TextImage'
import ProductFeatures from '../../components/ProductFeatures/ProductFeatures'
import SectionSeparator from '../../components/section-separator/section-separator.component'
import FadeIn from '../../components/FadeIn/FadeIn'
import SEO from '../../components/seo'

const slide = {
  logo: 'static/product-estate-logo.png',
  title: 'Adhub Auction',
  subtitle: 'Digital advertising for real estate agents.',
  backgroundImage: 'static/product-estate-stats.png'
}

const section = {
  heading: 'Effective Advertising Based on',
  keyword: 'Real Data',
  keywordType: 'blue',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio.',
  imageUrl: 'static/adhub-case-raport.png'
}
const section2 = {
  heading: 'Automation and',
  keyword: 'Ease of Use',
  keywordType: 'salmon',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio.',
  imageUrl: 'static/adhub-case-campaign.png'
}
const section3 = {
  heading: 'Reporting',
  keyword: 'Automatic',
  keywordType: 'green',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio.',
  imageUrl: 'static/adhub-case-dashboard.png'
}

const section4 = {
  heading: 'End to end automatic lead generation for real estate.',
  keyword: 'Ejendomsmægler',
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pellentesque, odio eget tincidunt varius, nulla diam luctus nisl, et euismod enim est aliquet ex. Integer quis sapien velit. Nullam condimentum nulla odio.',
  imageUrl: 'static/adhub-case-dashboard.png'
}

const features = [
  {
    icon: 'icon-images/squares-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/dots-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/center-mark-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/squares-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/dots-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/bars-icons.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/honeycomb-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  },
  {
    icon: 'icon-images/bermuda-icon.png',
    headline: 'Feature name',
    text:
      'Lorem ipsum dolor sit amet consectetuer adisciping elit aenean sum elit es.'
  }
]

const Auction = () => (
  <Layout>
    <SEO title="Auction" description={`Adhub`} />
    <IntroHero slide={slide} />

    <FadeIn element={'.fadeIn-1'}>
      <div className="fadeIn-1">
        <TextImage section={section} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-2'}>
      <div className="fadeIn-2">
        <TextImage section={section2} leftAlign={true} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-3'}>
      <div className="fadeIn-3">
        <TextImage section={section3} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-4'}>
      <div className="fadeIn-4">
        <SectionSeparator clipLeft />
        <ProductFeatures features={features} />
        <SectionSeparator clipRight />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-5'}>
      <div className="fadeIn-5">
        <TextImage
          section={section4}
          type="btn"
          logo="case-images/edc-logo.png"
        />
      </div>
    </FadeIn>
  </Layout>
)

export default Auction
