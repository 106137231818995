import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Note: You can change "images" to whatever you'd like.

const ImageRenderer = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageSizes = {
        width: props.width,
        height: props.height
      }

      return (
        <Img
          alt={props.alt}
          {...props}
          style={imageSizes}
          fluid={image.node.childImageSharp.fluid}
        />
      )
    }}
  />
)

export default ImageRenderer
