import React, { useState, useRef, useEffect } from "react"
import Gsap from "gsap"
import { Link } from "gatsby"

import "./MobileNav.scss"

const MobileNav = ({ props, closeMenu }) => {
  // Create varibles of our dom nodes
  let menuLayer = useRef(null)
  let reveal1 = useRef(null)
  let reveal2 = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let product1 = useRef(null)
  let product2 = useRef(null)
  let product3 = useRef(null)
  let mainLinks = useRef(null)
  let productLinks = useRef(null)
  let caseLinks = useRef(null)
  let case1 = useRef(null)
  let case2 = useRef(null)
  let case3 = useRef(null)

  const [state, setState] = useState({
    products: false,
    cases: false,
  })

  useEffect(() => {
    if (!props.isMenuOpen) {
      Gsap.to([reveal2, reveal1], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07,
        },
      })
      Gsap.to(menuLayer, { duration: 1, css: { display: "none" } })
    } else if (
      props.isMenuOpen ||
      (props.isMenuOpen && props.initial === null)
    ) {
      Gsap.to(menuLayer, {
        duration: 1,
        css: { display: "block" },
      })
      Gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
      })
      Gsap.to([reveal1, reveal2], {
        duration: 0.8,
        height: "100%",
        ease: "power3.inOut",
        stagger: {
          amount: 0.1,
        },
      })
      // , line4, line5
      Gsap.from([line1, line2, line3, line4], {
        duration: 0.5,
        y: 100,
        delay: 0.1,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      })

      if (state.products) {
        Gsap.to(mainLinks, {
          duration: 0,
          css: { display: "none" },
        })
        Gsap.to(productLinks, {
          duration: 0,
          css: { display: "block" },
        })
        Gsap.from([product1, product2, product3], {
          duration: 0.5,
          y: 100,
          delay: 0.1,
          ease: "power3.inOut",
          stagger: {
            amount: 0.3,
          },
        })
      } else if (state.cases) {
        Gsap.to(mainLinks, {
          duration: 0,
          css: { display: "none" },
        })
        Gsap.to(caseLinks, {
          duration: 0,
          css: { display: "block" },
        })
        Gsap.from([case1, case2, case3], {
          duration: 0.5,
          y: 100,
          delay: 0.1,
          ease: "power3.inOut",
          stagger: {
            amount: 0.3,
          },
        })
      } else {
        Gsap.to(mainLinks, {
          duration: 0,
          css: { display: "flex" },
        })
        Gsap.to(productLinks, {
          duration: 0,
          css: { display: "none" },
        })
        Gsap.to(caseLinks, {
          duration: 0,
          css: { display: "none" },
        })
      }
    }
  }, [props, state])

  const toggleProducts = () => {
    setState({
      products: !state.products,
    })
  }

  const toggleCases = () => {
    setState({
      cases: !state.cases,
    })
  }

  const removeOverflow = () => {
    if (document.body.style.overflow === "hidden") {
      document.body.style.overflow = "auto"
      closeMenu()
    }
  }

  return (
    <React.Fragment>
      <div ref={el => (menuLayer = el)} className="hamburger-menu">
        <div
          ref={el => (reveal1 = el)}
          className="menu-secondary-background-color"
        />
        <div ref={el => (reveal2 = el)} className="menu-layer">
          <div className="container">
            <div className="wrapper">
              <div className="menu-links">
                <nav>
                  <ul ref={el => (mainLinks = el)}>
                    <li ref={el => (line1 = el)}>
                      <Link onClick={removeOverflow} className="" to="/">
                        Home
                      </Link>
                    </li>

                    {/*
										
											{props.productLinks && (
                      <li onClick={toggleProducts} ref={el => (line2 = el)}>
                        <a href="#0">Products</a>
                      </li>
                    )}

                    {props.caseLinks && (
                      <li onClick={toggleCases} ref={el => (line3 = el)}>
                        <a href="#0">Cases</a>
                      </li>
                    )}
										
										*/}

                    <li ref={el => (line2 = el)}>
                      <Link onClick={removeOverflow} to="/products/estate">
                        Adhub Estate 
                      </Link>
                    </li>

                    <li ref={el => (line3 = el)}>
                      <Link onClick={removeOverflow} to="/about">
                        About Us
                      </Link>
                    </li>
                    <li ref={el => (line4 = el)}>
                      <Link onClick={removeOverflow} to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>

                  <ul ref={el => (productLinks = el)}>
                    <li>
                      <div onClick={toggleProducts}>Back</div>
                    </li>
                    <li ref={el => (product1 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/products/estate"
                      >
                        Adhub Estate
                      </Link>
                    </li>
                    <li ref={el => (product2 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/products/auto"
                      >
                        Adhub Auto
                      </Link>
                    </li>
                    <li ref={el => (product3 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/products/auction"
                      >
                        Adhub Auction
                      </Link>
                    </li>
                  </ul>

                  <ul ref={el => (caseLinks = el)}>
                    <li>
                      <div onClick={toggleCases}>Back</div>
                    </li>
                    <li ref={el => (case1 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/cases/estate"
                      >
                        Case Estate
                      </Link>
                    </li>
                    <li ref={el => (case2 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/cases/auto"
                      >
                        Case Auto
                      </Link>
                    </li>
                    <li ref={el => (case3 = el)}>
                      <Link
                        onClick={removeOverflow}
                        className=""
                        to="/cases/auction"
                      >
                        Case Auction
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MobileNav
