import React, { useRef } from 'react'
import ImageRenderer from '../ImageRenderer/ImageRenderer'
import { Link } from 'gatsby'

import { InfoBoxContainer } from './info-box.styled'

import './infoBoxes.scss'

const InfoBox = (props) => {
  let infoBoxes = useRef(null);

  return (
    <section ref={infoBoxes} className="info-boxes">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h1 className="info-boxes__title fs-8 primary-font font-bold text-dark">
              What we do
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 d-flex">
            <InfoBoxContainer
              align="left-column"
              {...props}
            >
              <div className="info-boxes__logo">
                <ImageRenderer
                  alt="digital-automation-icon"
                  width="88px"
                  filename="digital-automation-icon.png"
                />
              </div>

              <p className="info-boxes__content  color-primary fs-7 lh-56p text-center">                
                MarTech products for real estate, automotive and retail
              </p>

              <div className="info-boxes__action d-flex align-items-center">
                <ImageRenderer
                  alt="arrow-down"
                  width="34px"
                  filename="arrow-down-blue.png"
                />
                <span className="ml-2 color-secondary">
                  <Link className="fs-3" to="/products/estate">
                    View Our Products
                  </Link>
                </span>
              </div>
            </InfoBoxContainer>
          </div>

          <div className="col-12 col-lg-6 d-flex">
            <InfoBoxContainer
              align="right-column"
              {...props}
            >
              <div className="info-boxes__logo">
                <ImageRenderer
                  alt="digital-automation-icon"
                  width="80px"
                  filename="custom-automation-icon.png"
                />
              </div>

              <p className="info-boxes__content color-primary fs-7 lh-56p  font-semi-bold text-center">
              MarTech can help you automate and optimize your business
              </p>

              <div className="info-boxes__action d-flex align-items-center">
                <Link
                  className="btn btn-primary color-gold fs-4 font-semi-bold"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </div>
            </InfoBoxContainer>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InfoBox
