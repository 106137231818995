import React from 'react'

import Layout from '../../layout/layout'
import IntroHero from '../../components/IntroHero/IntroHero'
import TextImage from '../../components/TextImage/TextImage'
import ProductFeatures from '../../components/ProductFeatures/ProductFeatures'
import SectionSeparator from '../../components/section-separator/section-separator.component'
import FadeIn from '../../components/FadeIn/FadeIn'
import SEO from '../../components/seo'

const slide = {
  logo: 'static/product-estate-logo.png',
  title: 'Adhub Estate',
  subtitle:
    'Providing you with leads by enabling data driven ad campaigns.',
  backgroundImage: 'static/product-estate.png'
}

const section = {
  heading: 'at your disposal',
  keyword: 'Multichannel statistics',
  keywordType: 'blue',
  content:
    'We gather statistics from all marketing channels and combine them into a single interface so you can access them quickly and share the data easily with your clients.',
  imageUrl: 'static/adhub-case-report-modified.png'
}
const section2 = {
  heading: 'campaign generation',
  keyword: 'One click', 
  keywordType: 'salmon',
  content:
    'Set up a multichannel campaign with a flip of a switch or create an automatic workflow based on your business needs. You can edit all information and change the target area and segments. Campaign performance are safeguarded by the Adhub Truetarget algorithm.',
  imageUrl: 'static/adhub-case-campaign.png'
}
const section3 = {
  heading: 'with your data',
  keyword: 'Always up to speed ',
  keywordType: 'green',
  content:
    'We create aggregated reporting and invoicing based on monthly activity, including  individual real estate agencies that are working under a franchise or a larger company.',
  imageUrl: 'static/agency-dashboard-screen-static.png'
}

const section4 = {
  heading: 'your business safe.',
  keyword: 'Keeping',
  keywordType: 'green',
  content:
    'Whether you are  a company or franchise holder, we make sure that your brand’s safety is maintained throughout all campaigns ran by subsidiaries or different franchises.',
  imageUrl: 'static/adhub-branding-campaign.png'
}

const features = [
  {
    icon: 'icon-images/remarketing-campaigns-accent.png',
    headline: 'Cross platform advertising',
    text:
      'Automated advertising on Google, Facebook, Instagram and Adform'
  },
  {
    icon: 'icon-images/campaign-target-accent.png',
    headline: 'Adhub TrueTarget',
    text:
      'Optimized targeting based on your business data empowered by machine learning and AI'
  },
  {
    icon: 'icon-images/interest-groups-accent.png',
    headline: 'Segments',
    text:
      'TrueTarget builds and optimises relevant audiences through tracking on your business platforms.'
  },
  {
    icon: 'icon-images/bermuda-icon.png',
    headline: 'Integration',
    text:
      'Integrated with your business IT systems'
  },
  {
    icon: 'icon-images/campaign-dashboard-accent.png',
    headline: 'Campaign Dashboard',
    text:
      'Simple. Fast. Intuitive. The campaign dashboard is easy to work with and it showcases an overview of all the running and finished campaigns. '
  },
  {
    icon: 'icon-images/remarketing-campaigns-accent.png',
    headline: 'Remarketing Campaigns',
    text:
      'Re-targeting the undecisive clients with key messages so they can convert.'
  },
  {
    icon: 'icon-images/insights-accent.png',
    headline: 'Insights',
    text: 'You get monthly insights so you can optimize your costs.'
  },
  {
    icon: 'icon-images/campaign-editor-accent.png',
    headline: 'Campaign Creator',
    text:
      'So intuitive that anyone in your team can set up an effective campaign. Set the campaign budget, running dates and your ads are ready to go. '
  },
  {
    icon: 'icon-images/geotargeting-accent.png',
    headline: 'Geotargeting',
    text:
      'Select a small district or an entire country, you can geotarget any area that your business is relevant to.'
  },
  {
    icon: 'icon-images/automatic-ab-testing-accent.png',
    headline: 'Automatic A/B Testing',
    text:
      'Add two or more text messages at the beginning of the campaign and our platform selects the most performing one to run for the remaing period.'
  },
  {
    icon: 'icon-images/realtime-preview-accent.png',
    headline: 'Real-Time Preview',
    text:
      'You see exactly how your ads gonna look like on SoMe channels, Google Ads and Adform.'
  },
  {
    icon: 'icon-images/branding-campaigns-accent.png',
    headline: 'Branding Campaigns',
    text:
      'If one of the properties is marked sold, our platform reshuffles the budget and automatically creates a branding campaign.'
  },
  {
    icon: 'icon-images/branding-campaign-editor-accent.png',
    headline: 'Branding campaign editor',
    text:
      'Your team can edit or save presets campaigns to be used as individual branding campaigns or to replace a lead campaign when a property is marked sold.'
  },
  {
    icon: 'icon-images/landing-page-editor-accent.png',
    headline: 'Landing Page Editor',
    text:
      'Create simple landing pages in a few easy steps to support your advertising campaign'
  },
  {
    icon: 'icon-images/customized-budget-options-accent.png',
    headline: 'Customized Budget Options',
    text: 'We preset the budget to streamline the results. No more cost loss.'
  },
  {
    icon: 'icon-images/campaign-overview-accent.png',
    headline: 'Campaign Overview',
    text: 'Always up to date with day-to-day aggregated statistics.'
  },
  {
    icon: 'icon-images/customized-reports-accent.png',
    headline: 'Customized Reports',
    text:
      'No more cryptic reports. Adhub generates transparent reports based on default or custom templates that anyone can uderstand.'
  },
  {
    icon: 'icon-images/interest-groups-accent.png',
    headline: 'Interest Groups',
    text:
      'Set branding campaigns targeted across specific interest groups on all supported ad delivery systems.'
  },
  {
    icon: 'icon-images/campaign-target-accent.png',
    headline: 'Campaign Target',
    text:
      'Campaign Targets can be URLs, uploaded PDFs or landing pages that are created with the Landing page editor.'
  },
  {
    icon: 'icon-images/campaign-timeline-accent.png',
    headline: 'Campaign Timeline',
    text:
      'Easy to track visual timeline of events that happened during the life-cycle of the campaign.'
  }
]

const Estate = () => (
  <Layout>
    <SEO title="Adhub Estate" description={`Providing you with leads by enabling data driven ad campaigns.`} />

    <IntroHero slide={slide} />

    <FadeIn element={'.fadeIn-1'}>
      <div className="fadeIn-1">
        <TextImage section={section2} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-2'}>
      <div className="fadeIn-2">
        <TextImage section={section} leftAlign={true} />
      </div>
    </FadeIn>

    <FadeIn element={'.fadeIn-3'}>
      <div className="fadeIn-3">
        <TextImage section={section3} />
      </div>
    </FadeIn>

    <SectionSeparator clipLeft />
    <ProductFeatures headline="Product Features" features={features} />
    <SectionSeparator clipRight />

    <FadeIn element={'.fadeIn-5'}>
      <div className="fadeIn-5">
        <TextImage section={section4} />
      </div>
    </FadeIn>
  </Layout>
)

export default Estate
