import React from "react"
import ImageRenderer from "../ImageRenderer/ImageRenderer"
import { Link } from "gatsby"

import "./case-info-box.styles.scss"

const CaseInfoBox = ({ section: { title, text, link, bullets } }) => {
  return (
    <section className="bg-accent case-info-box">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h1 className="fs-7 mb-3">{title}</h1>
            <p
              className="fs-4 mb-2"
              style={{ color: "#637699", lineHeight: "40px" }}
            >
              {text}
            </p>
            
            {bullets && (
              <ul className="fs-4" style={{ color: "#637699", lineHeight: "40px" }}>
                {bullets.map((bullet, idx) => (
                  <li key={idx}>{bullet}</li>
                ))}
              </ul>
            )}

            <div className="mt-5">
              {link.includes("http") && (
                <a
                  className="btn btn-primary-accent color-gold fs-4 font-semi-bold"
                  href={link}
                  target="_blank"
                >
                  See Award
                </a>
              )}

              {!link.includes("http") && (
                <Link
                  className="btn btn-primary-accent color-gold fs-4 font-semi-bold"
                  to={link}
                >
                  See Award
                </Link>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <ImageRenderer
              alt="Logo"
              className="case-info-box__image d-none d-lg-block"
              filename="digital-award-adhub-styled.png"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseInfoBox
