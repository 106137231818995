import React from "react"

import Layout from "../layout/layout"
import IntroHero from "../components/IntroHero/IntroHero"
import SEO from "../components/seo"

const slide = {
  title: "Privacy Policy",
  subtitle: "Updated: 15 June 2020",
}

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" description={`Your privacy is important to us. It is AdHub ApS' policy to respect
            your privacy regarding any information we may collect from you
            across our website`} />
    <IntroHero slide={slide} />

    <div className="container my-5 py-5">
      <div className="row">
        <div className="col-12">
          <h1 className="fs-8 primary-font mb-5">Privacy Policy</h1>

          <p className="fs-4 lh-40p text-light">
            Your privacy is important to us. It is AdHub ApS' policy to respect
            your privacy regarding any information we may collect from you
            across our website, https://adhub.dk, and other sites we own and
            operate.
          </p>
          <p className="fs-4 lh-40p text-light mb-5">
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </p>

          <p className="fs-4 lh-40p text-light mb-5">
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </p>
          <p className="fs-4 lh-40p text-light mb-5">
            We don’t share any personally identifying information publicly or
            with third-parties, except when required to by law.
          </p>
          <p className="fs-4 lh-40p text-light mb-5">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </p>
          <p className="fs-4 lh-40p text-light mb-5">
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>
          <p className="fs-4 lh-40p text-light mb-5">
            Your continued use of our website will be regarded as acceptance of
            our practices around privacy and personal information. If you have
            any questions about how we handle user data and personal
            information, feel free to contact us.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
