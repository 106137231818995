import React from "react"
import ImageRenderer from "../ImageRenderer/ImageRenderer"
import { Link } from "gatsby"

import "./product-showcase.styles.scss"

const ProductShowcase = ({
  section: { logo, title, text, link, linkLabel, image, btnVariant },
}) => {
  return (
    <section className="bg-accent product-showcase">
      <div className="product-showcase_container container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center justify-content-lg-start">
            <div className="product-showcase__text">
              <div className="mb-3">
                <div className="product-showcase__logo d-flex justify-content-center justify-content-lg-start">
                  <ImageRenderer
                    alt={logo}
                    className="d-lg-block"
                    filename={logo}
                    width="76px"
                  />
                </div>
              </div>
              <h1 className="product-showcase__heading primary-font fs-8 text-center text-lg-left">{title}</h1>
              <p className="fs-7 color-primary text-center text-lg-left">{text}</p>

              <div className="product-showcase__btn d-flex justify-content-center justify-content-lg-start">
                {link.includes("http") && (
                  <a
                    className={`btn ${btnVariant} fs-4 font-semi-bold`}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {linkLabel}
                  </a>
                )}

                {!link.includes("http") && (
                  <Link
                    className={`btn ${btnVariant} fs-4 font-semi-bold`}
                    to={link}
                  >
                    {linkLabel}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pr-lg-0 d-flex justify-content-end">
            <ImageRenderer
              alt={image}
              className="product-showcase__image d-none d-lg-block"
              filename={image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductShowcase
